.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip-trigger {
  > *:disabled,
  > .is-disabled {
    pointer-events: none;
  }
}

.tooltip-box {
  padding: $tooltip-padding;
  border-radius: $global-border-radius;
  border: $tooltip-border;
  background: $tooltip-bg;
  position: absolute;
  display: block;
  opacity: 0;
  transition: opacity $tooltip-transition;
  pointer-events: none;
  word-wrap: break-word;
  white-space: nowrap;
  text-align: center;
  z-index: $z-index-tooltip;
  color: $tooltip-color;
  font-size: $tooltip-font-size;
  margin: $tooltip-margin;

  &[data-visible="true"] {
    opacity: 1;
    pointer-events: auto;
  }

  .modal-dialog & {
    z-index: $z-index-tooltip-in-modal;
  }

  @if $tooltip-arrow-enabled {
    @include create-flow-property("left", 0); // This is a fallback.
    bottom: 100%;

    @include create-down-arrow(
      $tooltip-arrow-offset,
      $tooltip-arrow-fill-color,
      $tooltip-arrow-border-color
    );

    &.is-drop-inline-end {
      @include create-flow-property("left", 100%);
      @include create-flow-property("margin-left", $global-arrow-size);
      margin-top: 0;
      margin-bottom: 0;
      bottom: auto;
      top: 0; // This is a fallback.

      @include create-left-arrow(
        $tooltip-arrow-offset,
        $tooltip-arrow-fill-color,
        $tooltip-arrow-border-color
      );
    }

    &.is-drop-inline-start {
      @include create-flow-property("right", 100%);
      @include create-flow-property("left", auto);
      @include create-flow-property("margin-right", $global-arrow-size);
      margin-top: 0;
      margin-bottom: 0;
      bottom: auto;
      top: 0; // This is a fallback.

      @include create-right-arrow(
        $tooltip-arrow-offset,
        $tooltip-arrow-fill-color,
        $tooltip-arrow-border-color
      );
    }

    &.is-drop-block-end {
      @include create-flow-property("left", 0); // This is a fallback.
      top: 100%;
      margin: -$global-arrow-size 0 0 0;
      bottom: auto;

      @include create-up-arrow(
        $tooltip-arrow-offset,
        $tooltip-arrow-fill-color,
        $tooltip-arrow-border-color
      );
    }
  }
}
