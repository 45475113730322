a {
  font-family: $link-font;
  font-size: $link-font-size;
  font-style: $link-style;
  font-weight: $link-weight;
  color: $link-color;
  text-decoration: $link-text-decoration;

  &:visited {
    color: $link-color;
  }

  &:hover,
  &:focus {
    color: $link-hover;
    text-decoration: $link-text-decoration-hover;
  }

  &:active {
    color: $link-active;
  }
}

input[type="button"],
input[type="submit"],
input[type="file"],
input[type="reset"],
button,
.button {
  -webkit-appearance: none;
  align-self: flex-start;
  border-radius: $button-border-radius;
  border-style: $button-border-style;
  border-width: $button-border-width;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: $button-font;
  font-size: $button-font-size;
  font-style: $button-font-style;
  font-weight: $button-font-weight;
  line-height: $global-line-height;
  margin: $button-margin;
  padding: $button-padding;
  text-align: center;
  text-decoration: $button-text-decoration;
  transition: $global-transition;

  &::-moz-focus-inner {
    border: none;
  }

  @each $state, $properties in $button-base-states {
    @if $state != "default" {
      &:#{$state} {
        @each $property, $value in $properties {
          #{$property}: $value;
        }
      }
    } @else {
      @each $property, $value in $properties {
        #{$property}: $value;
      }

      &:visited {
        @each $property, $value in $properties {
          #{$property}: $value;
        } // end @each
      }
    } // end @if
  } // end @each

  @each $size, $properties in $button-sizes {
    &.is-#{$size} {
      @each $property, $value in $properties {
        #{$property}: $value;
      } // end @each
    }
  } // end @each

  &.is-wide {
    display: block;
    max-width: 100%;
    width: 100%;
  }

  @each $type, $states in $button-types {
    @each $state, $properties in $states {
      @if $state != "default" {
        &.is-#{$type} {
          &:#{$state} {
            @each $property, $value in $properties {
              #{$property}: $value;
            }
          }
        }
      } @else {
        &.is-#{$type} {
          @each $property, $value in $properties {
            #{$property}: $value;
          }

          &:visited {
            @each $property, $value in $properties {
              #{$property}: $value;
            }
          }
        }
      } // end @if
    } // end @each
  } // end @each
} // end .button
