h1,
h2,
h3,
h4,
h5,
h6,
p,
em,
small,
mark {
  font-family: $global-font-sans;
}

ul,
ol {
  @include create-flow-property("padding-left", $type-list-padding-start);
  padding-bottom: $type-list-padding-bottom;
  margin: $type-list-margin;
  font-family: $type-list-font;

  li {
    margin: $type-list-item-margin;
    padding: $type-list-item-padding;
  }
}

ul {
  list-style-type: $type-ul-list-style;
}

ol {
  list-style-type: $type-ol-list-style;
}

@each $header, $properties in $type-header-styles {
  #{$header},
  .#{$header} {
    @each $property, $value in $properties {
      #{$property}: $value;
    }
  }
}

@each $size, $headers in $type-header-sizes {
  // Returns index of the current $key + $value pair
  $bp-index: index($type-header-sizes, ($size $headers));

  // If the index is on the first item in $$type-header-sizes
  @if $bp-index == 1 {
    @include create-headers($headers);

    // Else add a media query
  } @else {
    @include create-breakpoint($size) {
      @include create-headers($headers);
    }
  }
}

p,
.paragraph {
  font-size: $type-p-font-size;
  font-style: $type-p-font-style;
  font-weight: $type-p-font-weight;
  line-height: $type-p-line-height;
  margin-bottom: $type-p-margin-bottom;
  color: $type-p-color;
}

pre,
code,
kbd,
samp,
tt,
.pre,
.code,
.kbd,
.samp,
.tt {
  font-family: $type-code-font;
  font-size: $type-code-font-size;
  background: $type-code-bg;
  border: $type-code-border;
  border-radius: $type-code-radius;
  padding: $type-code-padding;
  color: $type-code-color;
}

pre,
.pre {
  padding: $type-pre-padding;
  overflow: auto;
  overflow-wrap: initial;

  > code,
  .code {
    word-break: normal;
    width: auto;
  }
}

strong,
.strong {
  font-size: inherit;
  font-style: $type-strong-font-style;
  font-weight: $type-strong-font-weight;
}

em,
.em {
  font-size: inherit;
  font-style: $type-em-font-style;
  font-weight: $type-em-font-weight;
}

mark,
.mark {
  font-size: inherit;
  padding: $type-mark-padding;
  background: $type-mark-bg;
  color: $type-mark-color;
}

small,
.small {
  font-weight: $type-small-font-weight;
  font-size: $type-small-font-size;
}

u,
.underline {
  text-decoration: $type-underline-text-decoration;
}

hr {
  margin: $hr-margin;
  border-width: 0;
  border-top: $hr-border;
}
