.no-scroll {
  overflow: hidden !important;
}

.overlay-open {
  cursor: pointer;
}

$inputs: "";

@each $type in $form-input-field-types {
  $inputs: $inputs + ":not([type='#{$type}'])";
}

.using-keyboard *#{$inputs}:not(textarea):not(select):focus {
  box-shadow: $global-focus-ring !important;
}
