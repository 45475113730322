@each $type in $form-input-field-types {
  input[type="#{$type}"] {
    @include create-input-field-base-styles;
  }
}

textarea {
  @include create-input-field-base-styles;
  @include create-input-field-interactive-styles;
  resize: $form-textarea-resize;
}

fieldset {
  display: flex;
  flex-direction: column;
  background: $form-fieldset-bg;
  border-radius: $form-fieldset-border-radius;
  border: $form-fieldset-border;
  box-shadow: $form-fieldset-box-shadow;
  margin: $form-fieldset-margin;
  padding: $form-fieldset-padding;

  legend {
    align-self: flex-start;
    background: $form-fieldset-legend-bg;
    border-radius: $form-fieldset-legend-border-radius;
    border: $form-fieldset-legend-border;
    box-shadow: $form-fieldset-legend-box-shadow;
    color: $form-fieldset-legend-color;
    font-family: $form-fieldset-legend-font-family;
    font-size: $form-fieldset-legend-font-size;
    padding: $form-fieldset-legend-padding;
    margin: $form-fieldset-legend-margin;
  }
}

form {
  background: $form-bg;
  border: $form-border;
  box-shadow: $form-box-shadow;
  margin: $form-margin;
  padding: $form-padding;
}

label {
  display: flex;
  flex-direction: column;
  font-family: $form-font-family;
}

.has-check {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: $form-input-check-label-padding;
}

input[type="radio"],
input[type="checkbox"] {
  cursor: pointer;
  font-family: $form-font-family;
  margin: $form-input-check-margin;
  padding: $form-input-check-padding;
}

@each $type in $form-input-field-types {
  input[type="#{$type}"] {
    @include create-input-field-interactive-styles;
  }
}

@each $type in $form-input-field-types {
  @include create-input-of-type-disabled($type);
}

@each $type in $form-input-button-types {
  @include create-input-of-type-disabled($type);
}

@each $type in $form-input-check-types {
  @include create-input-of-type-disabled($type);
}

textarea,
button,
.button {
  &:disabled,
  &.is-disabled {
    @include create-input-disabled-styles;
  }
}
