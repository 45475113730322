@function custom-indicators-configured() {
  @return $collapsible-indicator-open != "" and $collapsible-indicator-closed != "";
}

.collapsible-trigger {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  text-decoration: none;
  align-items: center;
  width: 100%;
  border: none;
  color: $collapsible-trigger-color;
  background: $collapsible-trigger-bg;
  margin: 0;
  font-size: $collapsible-trigger-font-size;

  &:hover,
  &:focus {
    color: $collapsible-trigger-color-hover;
    background: $collapsible-trigger-bg-hover;
  }

  &::after {
    font-size: 12px;
    font-weight: $collapsible-indicator-weight;

    @if custom-indicators-configured() {
      content: $collapsible-indicator-open;
    } @else if $collapsible-indicator {
      content: $collapsible-indicator;
    }
  }

  &.is-ready::after {
    transition: transform $collapsible-transition-duration $collapsible-transition-ease;
  }
}

.collapsible {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 0;
    margin: 0;
  }

  &[data-visible="false"] .collapsible-trigger::after {
    @if custom-indicators-configured() {
      content: $collapsible-indicator-closed;
    } @else if $collapsible-indicator {
      transform: $collapsible-indicator-transform;
    }
  }
}

.collapsible-content {
  overflow: hidden;
  visibility: hidden;
  padding: 0;
  margin: 0;
  height: 0;

  &.is-visible {
    visibility: visible;
  }

  &.is-ready {
    transition-property: visibility, height;
    transition-duration: $collapsible-transition-duration;
    transition-timing-function: $collapsible-transition-ease;
  }
}
