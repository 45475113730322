@import "../vendor/functions";

@function breakpoint-value($name) {
  @return map-get($grid-breakpoints, $name);
}

$px-to-em-base-font-size: 16px !default;

@function px-to-em($numerator, $divisor: $px-to-em-base-font-size) {
  @return #{$numerator / $divisor}em;
}

@function spacing-value($increment) {
  @return map-get($global-spacing-increments, "#{$increment}");
}
