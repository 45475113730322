$grid: map-get($grid-classes, grid);
$row: map-get($grid-classes, row);
$column: map-get($grid-classes, column);

.#{$grid}::before,
.#{$grid}::after,
.#{$grid} .#{$row}::before,
.#{$grid} .#{$row}::after,
.#{$row} .#{$column}::before,
.#{$row} .#{$column}::after {
  display: block;
  height: 0;
  width: 0;
  padding: 0;
  margin: 0;
}

.#{$grid} {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 0;
  box-sizing: border-box;
  margin: 0 auto;
  max-width: $grid-width;
  padding: $grid-padding;
  position: relative;

  @each $name, $width in $grid-widths {
    &.is-#{$name} {
      max-width: $width;
    }
  }

  @each $name, $padding in $grid-sections {
    &.is-section-#{$name} {
      padding-top: $padding;
      padding-bottom: $padding;
    }
  }

  &.is-fullscreen {
    box-sizing: border-box;
    height: 100vh;
    max-width: initial;
    width: 100vw;

    .#{$row} {
      flex: 1 1 0;
      align-items: center;
    }
  }
}

.#{$row} {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  flex: 1 1 auto;
  position: relative;
  max-width: 100%;
  min-height: 1px;
  padding: 0;
  margin: 0;
}

.#{$column} {
  box-sizing: border-box;
  flex: 1 1 auto;
  max-width: 100%;
  min-height: 1px;
  min-width: 1px;
  padding: $grid-column-padding-block-start $grid-column-padding-inline
    $grid-column-padding-block-end $grid-column-padding-inline;
  word-wrap: break-word;
}

@include create-grid-classes-generator;
